<script>
  import Group from "$lib/admin/components/Group.svelte";
  import TipTapEditor from "$lib/admin/components/content/TipTapEditor/TipTapEditor.svelte";
  import Block from "$lib/admin/components/blocks/helpers/Block.svelte";
  import { page } from "$app/stores";
  import { isTournamentsRoute } from "$lib/services/utils";
  import { project } from "$lib/services/store";
  import BlockBackgroundSelector from "../helpers/BlockBackgroundSelector.svelte";
  import { onMount } from "svelte";

  export let props = null;
  export let adminView = false;

  const tournamentRoute = $project.settings.tournamentsUrl ?? "turneringar";

  let hasParagraph = false;
  onMount(() => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(props.content, "text/html");
    hasParagraph = doc.querySelector("p") !== null;
  });
</script>

{#if adminView}
  <BlockBackgroundSelector bind:value={props.bgColor} on:change={(e) => (props.bgColor = e.detail.bgColor)} />
  <Group addClass="form-item">
    {#if props}
      <TipTapEditor bind:text={props.content} placeholder="Write some great content" />
    {/if}
  </Group>
{:else if isTournamentsRoute($page.url.pathname, tournamentRoute)}
  {#if props && props.content}
    <div style="background: {props.bgColor};padding: {hasParagraph ? '24px 0' : '0'};" class="dark-mode">
      <div class="container tournament-page">
        {@html props.content}
      </div>
    </div>
  {:else}
    <p class="empty-content">No content written yet. Write some text to get started!</p>
  {/if}
{:else}
  <Block
    blockClass={"block-image" + (props.imageBlockType ? " " + props.imageBlockType : "")}
    gridColumn={"g-col-12 g-start-1 g-col-md-10 g-start-md-2"}
    {props}
  >
    {#if props && props.content}
      {@html props.content}
    {:else}
      <p class="empty-content">No content written yet. Write some text to get started!</p>
    {/if}
  </Block>
{/if}

<style>
  :global(.dark-mode p span[style]) {
    color: #fff !important;
    background: none !important;
  }

  :global(.tournament-page a) {
    text-decoration: underline;
    text-decoration-color: #cc3129 !important;
    text-underline-offset: 8px;
    text-decoration-thickness: 3px;
    color: white;

    &:hover {
      color: lightgray;
    }
  }
  :global(.table-bordered) {
    width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
    border-collapse: separate;
    border-spacing: 0;
    margin-bottom: 1rem;
  }

  :global(.table-bordered p) {
    margin: 0!important;
  }

  :global(.table-bordered th:first-child),
  :global(.table-bordered td:first-child) {
    border-left: 1px solid #e2e8f0;
    border-top-left-radius: 6px;
  }

  :global(.table-bordered tbody tr:first-child td) {
    border-top: 1px solid #e2e8f0;
  }

  :global(.table-bordered th:last-child),
  :global(.table-bordered td:last-child) {
    border-right: 1px solid #e2e8f0;
    border-top-right-radius: 6px;
  }

  :global(.table-bordered tr:first-child th) {
    border-top: 1px solid #e2e8f0;
  }

  :global(.table-bordered tr:first-child th:first-child) {
    border-top-left-radius: 6px;
  }

  :global(.table-bordered tr:first-child th:last-child) {
    border-top-right-radius: 6px;
  }

  :global(.table-bordered tr:last-child td) {
    border-bottom: 1px solid #e2e8f0;
  }

  :global(.table-bordered tr:last-child td:first-child) {
    border-bottom-left-radius: 6px;
  }

  :global(.table-bordered tr:last-child td:last-child) {
    border-bottom-right-radius: 6px;
  }

  :global(.table-bordered th,
  .table-bordered td) {
    padding: 20px;
    vertical-align: top;
  }

  :global(.table-bordered thead th) {
    vertical-align: bottom;
    background-color: #f8fafc;
    color: #475569;
    font-weight: 600;
    border-bottom: 2px solid #e2e8f0;
  }

  :global(.table-bordered tbody tr:nth-child(even)) {
    background-color: white;
  }

  :global(.table-bordered tbody tr:nth-child(odd)) {
    background-color: #f8fafc;
  }


  :global(.table-bordered tbody + tbody) {
    border-top: 2px solid #e2e8f0;
  }
</style>
