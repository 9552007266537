import { mergeAttributes } from '@tiptap/core'
import { BulletList } from '@tiptap/extension-bullet-list'

export const ConsList = BulletList.extend({
    name: 'consList',

    priority: 1000,

    addOptions() {
        return {
            ...this.parent?.(),
            itemTypeName: 'listItem',
            HTMLAttributes: {
                class: 'cons'
            },
        }
    },

    parseHTML() {
        console.log('ConsList parseHTML called')
        return [
            {
                tag: 'ul',
                getAttrs: node => {
                    console.log('ConsList parseHTML - checking node:', node instanceof HTMLElement ? node.className : 'not an element')
                    if (!(node instanceof HTMLElement)) return false
                    const hasCons = node.classList.contains('cons')
                    console.log('Has cons class:', hasCons)
                    return hasCons ? {} : false
                }
            }
        ]
    },

    renderHTML({ HTMLAttributes }) {
        console.log('ConsList renderHTML called with attributes:', HTMLAttributes)
        const attrs = mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)
        console.log('ConsList renderHTML - final attributes:', attrs)
        return ['ul', attrs, 0]
    },

    addCommands() {
        return {
            toggleConsList: () => ({ editor, commands }) => {
                console.log('ConsList toggleConsList command called')
                if (editor.isActive(this.name)) {
                    return commands.liftListItem(this.options.itemTypeName)
                }
                
                return commands.wrapInList(this.name)
            },
        }
    },

    addKeyboardShortcuts() {
        return {
            'Mod-Shift-f': () => this.editor.commands.toggleConsList(),
        }
    }
})
