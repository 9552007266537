<script lang="ts">
    import { createEventDispatcher } from 'svelte';
    import BoldIcon from 'virtual:icons/heroicons/bold';
    import ItalicIcon from 'virtual:icons/heroicons/italic';
    import UnderlineIcon from 'virtual:icons/heroicons/underline';
    import UndoIcon from 'virtual:icons/heroicons/arrow-uturn-left';
    import RedoIcon from 'virtual:icons/heroicons/arrow-uturn-right';
    import LinkIcon from 'virtual:icons/heroicons/link';
    import ChevronUpIcon from 'virtual:icons/heroicons/chevron-up';
    import ChevronDownIcon from 'virtual:icons/heroicons/chevron-down';
    export let editor;
    export let showFullMenu = false;

    const dispatch = createEventDispatcher();
    let canUndo = false;
    let canRedo = false;

    const updateStates = () => {
        if (editor) {
            canUndo = editor.can().undo();
            canRedo = editor.can().redo();
        }
    };

    $: if (editor) {
        updateStates();
        editor.on('transaction', updateStates);
    }

    const addLink = () => {
        const url = window.prompt('URL');
        if (url) {
            editor.chain().focus().setLink({ href: url }).run();
        }
    };
</script>

<div class="menu-bar compact">
    {#if editor}
        <div class="menu-group essential-controls">
            <button
                on:click={() => editor.chain().focus().toggleBold().run()}
                class:active={editor?.isActive('bold')}
                title="Bold">
                <BoldIcon />
            </button>
            <button
                on:click={() => editor.chain().focus().toggleItalic().run()}
                class:active={editor?.isActive('italic')}
                title="Italic">
                <ItalicIcon />
            </button>
            <button
                on:click={() => editor.chain().focus().toggleUnderline().run()}
                class:active={editor?.isActive('underline')}
                title="Underline">
                <UnderlineIcon />
            </button>
            <button
                on:click={() => editor.commands.undo()}
                disabled={!canUndo}
                title="Undo">
                <UndoIcon />
            </button>
            <button
                on:click={() => editor.commands.redo()}
                disabled={!canRedo}
                title="Redo">
                <RedoIcon />
            </button>
            <button
                on:click={addLink}
                class:active={editor?.isActive('link')}
                title="Add Link">
                <LinkIcon />
            </button>
        </div>
        
        <div class="menu-group menu-toggle">
            <button 
            on:click={() => dispatch('toggleMenu')} 
            title={showFullMenu ? 'Show Less' : 'Show More'}>
            {#if showFullMenu}
                <ChevronUpIcon />
            {:else}
                <ChevronDownIcon />
            {/if}
        </button>

        </div>
    {/if}
</div>

<style>
    .menu-bar.compact {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        gap: 8px;
        padding: 8px;
        border-bottom: 1px solid #ccc;
        background: #f5f5f5;
        max-width: 100%;
        box-sizing: border-box;
    }

    .menu-group {
        display: flex;
        gap: 4px;
        flex-wrap: nowrap;
        align-items: center;
    }

    .essential-controls {
        flex: 1;
        display: flex;
        gap: 4px;
    }

    .menu-toggle {
        margin-left: auto;
        padding-left: 8px;
        border-left: 1px solid #ddd;
    }

    button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 4px 8px;
        background: white;
        border: 1px solid #ddd;
        border-radius: 4px;
        cursor: pointer;
        font-size: 14px;
        min-width: 24px;
        height: 24px;
        white-space: nowrap;
    }

    button:hover {
        background: #f0f0f0;
    }

    button.active {
        background: #e0e0e0;
    }
</style>
