import { Extension, Node } from '@tiptap/core'
import { Node as ProseMirrorNode } from 'prosemirror-model'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    preTableDiv: {
      togglePreTableTitleDiv: () => ReturnType
    }
  }
}

export const PreTableDivNode = Node.create({
  name: 'preTableTitleDivNode',
  group: 'block',
  content: 'inline*',
  


  parseHTML() {
    return [
      {
        tag: 'div.pre-table-title'
      }
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', { class: 'pre-table-title', ...HTMLAttributes }, 0]
  }
})

export const PreTableTitleDiv = Extension.create({
  name: 'preTableTitleDiv',

  addExtensions() {
    return [
      PreTableDivNode
    ]
  },

  addGlobalAttributes() {
    return [
      {
        types: ['table'],
        attributes: {
          hasPreDiv: {
            default: false,
            keepOnSplit: true,
            parseHTML: element => {
              const value = element.getAttribute('data-has-pre-div')
              return value === 'true'
            },
            renderHTML: attributes => {
              return {
                'data-has-pre-div': attributes.hasPreDiv ? 'true' : 'false'
              }
            }
          }
        }
      }
    ]
  },

  addCommands() {
    return {
      togglePreTableTitleDiv: () => ({ editor, tr, state }) => {
        const { selection } = state
        const tableNode = findParentTable(selection.$from)
        
        if (!tableNode) {
          return false
        }

        const pos = tableNode.pos
        const node = tableNode.node
        const hasPreDiv = node.attrs.hasPreDiv
        const beforeTablePos = pos

        tr.setNodeMarkup(pos, null, {
          ...node.attrs,
          hasPreDiv: !hasPreDiv
        })

        if (!hasPreDiv) {
          const divNode = editor.schema.nodes.preTableTitleDivNode.create(
            null,
            editor.schema.text('Table title placeholder')
          )
          tr.insert(beforeTablePos, divNode)
        } else {          
          const $pos = tr.doc.resolve(beforeTablePos)
          const nodeBefore = $pos.nodeBefore

          if (nodeBefore && nodeBefore.type.name === 'preTableTitleDivNode') {
            tr.delete(beforeTablePos - nodeBefore.nodeSize, beforeTablePos)
          } else {
            const parent = $pos.node($pos.depth)
            const index = $pos.index($pos.depth)

            if (index > 0) {
              const prevNode = parent.child(index - 1)

              if (prevNode.type.name === 'preTableTitleDivNode') {
                let prevPos = beforeTablePos
                for (let i = index - 1; i < index; i++) {
                  prevPos -= parent.child(i).nodeSize
                }
                tr.delete(prevPos, prevPos + prevNode.nodeSize)
              }
            }
          }
        }

        return true
      }
    }
  }
})

function findParentTable(pos: any): { pos: number, node: ProseMirrorNode } | null {
  let depth = pos.depth
  while (depth > 0) {
    const node = pos.node(depth)
    if (node.type.name === 'table') {
      return {
        pos: pos.before(depth),
        node
      }
    }
    depth--
  }
  return null
}
