import { mergeAttributes } from '@tiptap/core'
import { BulletList } from '@tiptap/extension-bullet-list'

export const FireList = BulletList.extend({
    name: 'fireList',

    priority: 1000,

    addOptions() {
        return {
            ...this.parent?.(),
            itemTypeName: 'listItem',
            HTMLAttributes: {
                class: 'fire'
            },
        }
    },

    parseHTML() {
        console.log('FireList parseHTML called')
        return [
            {
                tag: 'ul',
                getAttrs: node => {
                    console.log('FireList parseHTML - checking node:', node instanceof HTMLElement ? node.className : 'not an element')
                    if (!(node instanceof HTMLElement)) return false
                    const hasFire = node.classList.contains('fire')
                    console.log('Has fire class:', hasFire)
                    return hasFire ? {} : false
                }
            }
        ]
    },

    renderHTML({ HTMLAttributes }) {
        console.log('FireList renderHTML called with attributes:', HTMLAttributes)
        const attrs = mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)
        console.log('FireList renderHTML - final attributes:', attrs)
        return ['ul', attrs, 0]
    },

    addCommands() {
        return {
            toggleFireList: () => ({ editor, commands }) => {
                console.log('FireList toggleFireList command called')
                if (editor.isActive(this.name)) {
                    return commands.liftListItem(this.options.itemTypeName)
                }
                
                return commands.wrapInList(this.name)
            },
        }
    },

    addKeyboardShortcuts() {
        return {
            'Mod-Shift-f': () => this.editor.commands.toggleFireList(),
        }
    }
})
